import { useEffect } from "react";
import { userManager } from "../../../common/user-service";
import { useNavigate } from "react-router-dom";
import { portalUrl } from "../../../common/urls";

function SigninCallback() {
	const navigate = useNavigate();

	useEffect(() => {
		const signInCallback = async () => {
			try {
				const user: any = await userManager.signinRedirectCallback();
				let returnURL: string | null = localStorage.getItem('redirect_url');
				localStorage.removeItem('redirect_url');
				returnURL = returnURL === null || returnURL === '' ? portalUrl : returnURL;
				
				window.location.replace(returnURL);

			} catch (error) {
				console.error("Error during callback:", error);
			}
		};

		signInCallback();
	}, [navigate]);

	return <div>Redirecting...</div>;
}

export default SigninCallback;
