import { UserManager } from 'oidc-client';
import { portalUrl, sarmUrl } from './urls';

const config = {
  authority: sarmUrl,
  client_id: process.env.REACT_APP_OIDC_CLIENT_ID,
  redirect_uri: portalUrl + '#/signin-callback?',
  response_type: process.env.REACT_APP_OIDC_RESPONSE_TYPE,
  scope: process.env.REACT_APP_OIDC_SCOPE,
  post_logout_redirect_uri: portalUrl + '/#/signout-oidc',
  store: process.env.REACT_APP_OIDC_STORE
};

export const userManager = new UserManager(config);