import { useState, useEffect } from "react";
import { AuditConfiguration } from '@ibcos/auditviewer';
import { userManager } from "../../../../common/user-service";
import { auditUrl } from "../../../../common/urls";

export default function AuditConfig() {
	const [accessTokenString, setAccessTokenString] = useState<string>("");

	useEffect(() => {
		const getAccessToken = async (): Promise<string> => {
			var user = await userManager.getUser();
			if (user.expired) {
				await userManager.signinSilent();
				user = await userManager.getUser();
			}
			const access_token_copy: string = user?.access_token;
			setAccessTokenString(access_token_copy);
		};

		getAccessToken();
	}, [getToken]);

	function getToken() {		
		return accessTokenString;
	}

	

	return (
		<div>
			<AuditConfiguration
				getToken={getToken}
				auditApiUrl={auditUrl}
			/>
		</div>
	);
}
