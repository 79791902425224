function getBase():string {
    let output = String(window.location.origin + '/').replace(process.env.REACT_APP_PORTAL_URL!, '')
    while(output.endsWith('/')) {
        output = output.substring(0, output.length - 1);
    };
    console.log('baseUrl: ' + output)
    return output;
}

const baseUrl: string = process.env.REACT_APP_SARM_URL === 'true' ? process.env.REACT_APP_REMOTE_BASE_URL! : getBase();

const sarmUrl: string = process.env.REACT_APP_SARM_USE_CUSTOM === 'true' ?
                            process.env.REACT_APP_SARM_CUSTOM_URL! :
                        baseUrl.includes('localhost') ? 
                            'https://localhost' +
                            process.env.REACT_APP_SARM_URL_LOCAL! :
                            baseUrl.replace('http://', 'https://') + 
                            process.env.REACT_APP_SARM_URL!;

const portalUrl: string = process.env.REACT_APP_PORTAL_USE_CUSTOM === 'true' ?
                            process.env.REACT_APP_PORTAL_CUSTOM_URL! :
                        baseUrl.includes('localhost') ? 
                            'http://localhost' +
                            process.env.REACT_APP_PORTAL_URL_LOCAL! :
                            baseUrl + 
                            process.env.REACT_APP_PORTAL_URL!;
     
const auditUrl: string = process.env.REACT_APP_AUDIT_USE_CUSTOM === 'true' ?
                            process.env.REACT_APP_AUDIT_CUSTOM_URL! :
                        baseUrl.includes('localhost') ? 
                            'https://localhost' +
                            process.env.REACT_APP_AUDIT_URL_LOCAL! :
                            baseUrl.replace('http://', 'https://') + 
                            process.env.REACT_APP_AUDIT_URL!;


export {portalUrl, sarmUrl, auditUrl}