import { createHashRouter, RouteObject, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import ManageUser from "../modules/portal/views/Users/ManageUser";
import ErrorPage from "../modules/portal/views/Common/error-page/error-page";
import MinimalLayout from "../modules/portal/views/MinimalLayout/MinimalLayout";
import SigninCallback from "../modules/portal/views/signin-callback";
import ManageRoles from "../modules/portal/views/Roles/ManageRoles/ManageRoles";
import AuditConfiguration from "../modules/portal/views/AuditConfig/AuditConfig";
import EmailValidationPage from "../modules/portal/views/Account/email-validate";
import PasswordResetPage from "../modules/portal/views/Account/reset-password";
import MyAccount from "../modules/portal/views/Account/my-account";
import NoLayout from "../modules/portal/views/NoLayout/NoLayout";

const HomeRedirect = () => {
	const navigate = useNavigate();

	useEffect(() => {
		navigate("/ext/account");
	}, [navigate]);

	return null;
};
const baseRoutes: RouteObject[] | any = [
	{
		path: "/", //Login page removed from this project. Auto redirect to 'home'
		element: <HomeRedirect />,
		meta: {
			module: false,
		},
	},
	{
		path: "/signin-callback", //Login page removed from this project. Auto redirect to 'home'
		element: <SigninCallback />,
		meta: {
			module: false,
		},
	},
	{
		path: "/email-validate/:email/:userName/:token", //email validation page
		element: <EmailValidationPage />,
		meta: {
			module: false,
		},
	},
	{
		path: "/reset-password/:email/:userName/:token", //password reset page
		element: <PasswordResetPage />,
		meta: {
			module: false,
		},
	},
	{
		path: "home",
		element: <MinimalLayout />,
		errorElement: <ErrorPage />,
		children: [
			{
				path: "users",
				element: <ManageUser />,
			},
			{
				path: "roles",
				element: <ManageRoles />,
			},
			{
				path: "auditconfig",
				element: <AuditConfiguration />,
			},
			{
				path: "my-account",
				element: <MyAccount showAudit={true} />, //syncfusion license error!
			},
		],
	},
	{
		path: "ext",
		element: <NoLayout />,
		errorElement: <ErrorPage />,
		children: [
			{
				path: "account",
				element: <MyAccount showAudit={false} />,
				errorElement: <ErrorPage />,
			}
		]
	},
];

const router = createHashRouter(baseRoutes);

// Merge in module routes
//const debug = process.env.NODE_ENV !== "production";

// // @ts-ignore
// export let routes = workshopRoutes.concat(marketplaceRoutes);

// if (debug) {
//   // @ts-ignore
//   routes = routes.concat(internalRoutes);
// }

// baseRoutes[0].children = routes;

export default router;
