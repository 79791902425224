import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { validateAccount, activateAccount } from '../../../../store/modules/UserSlice';
import { useAppDispatch } from '../../../../hooks/hooks';
import { UserValidate, UserActivate } from '../../models/UserDetail';
import { toast } from 'react-toastify';
import { Button } from 'react-bootstrap';
import NewPassword from '../NewPassword/NewPassword';
import { ResponseStatus } from '../../models/Enum';
import { registerLicense } from "@syncfusion/ej2-base";
import './onepage.css';
import logo from '../../../../assets/ibcos_black.jpg';
import Loading from '../../../../common/loading';
import { portalUrl } from '../../../../common/urls';

registerLicense(process.env.REACT_APP_SYNCFUSION_LICENSE_KEY as string);

interface Params {
  email: string;
  token: string;
  userName: string;
}

const EmailValidationPage: React.FC = () => {
    const [validationStatus, setValidationStatus] = useState<'validating' | 'password' | 'valid' | 'invalid' | 'error'>('validating');
    const { email } = useParams<Params>();
    const { userName } = useParams<Params>();
    const { token } = useParams<Params>();
    const dispatcher = useAppDispatch();
    const [password, setPassword] = useState<string>('');
    const [pwValid, setPwValid] = useState<boolean>(false);

    useEffect(() => {
        if (token) {
            switch (validationStatus) {
                case 'validating': 
                    ValidateDetails();
                    break;
                case 'password': 
                    break;
                case 'valid':
                    setTimeout(() => {
                        redirectNow();
                    }, 1500);
            }
        } else {
        setValidationStatus('error');
        }
    }, [token, validationStatus, email, userName]);

    function redirectNow() {
        let queryString = window.location.href.split('?')[1];
        const queryParams = new URLSearchParams(queryString);
        let redirUrl = "";
        
        if (queryParams.has("RedirectUrl")){
            redirUrl = queryParams.get("RedirectUrl")!
            redirUrl = decodeURIComponent(redirUrl);
        } else {
            redirUrl = portalUrl + '/#/home/my-account';
        }
        
        window.location.replace(redirUrl);
    }

    function ValidateDetails() {
        try {
            const userDetails: UserValidate = {
                email: email ?? '',
                username: userName ?? '',
                token: token ?? ''
            };

            dispatcher(validateAccount(userDetails)).then((response: any) => {
                
                if (response.payload !== undefined) {
                    if (response.payload.responseStatus === ResponseStatus.Success) {
                        if (response.payload.data === true) {
                            setValidationStatus('password');
                        } else {
                            setValidationStatus('invalid');
                        }
                    }
                } else {
                    toast.error("error checking details");
                    setValidationStatus('error');
                }
            });
        } catch (error: any) {
            toast.error("Unable to update password:  " + error);
        }
    }

    const handlePasswordValidation = (password: string, isValid: boolean) => {
        setPassword(password);
        setPwValid(isValid);
    }

    function updatePassword() {
        try {
            const userDetails: UserActivate = {
                email: email ?? '',
                username: userName ?? '',
                token: token ?? '',
                password: password
            };

            if (password.length === 0) {
                return;
            }

            dispatcher(activateAccount(userDetails)).then((response: any) => {
                
                if (response.payload !== undefined) {
                    if (response.payload.responseStatus === ResponseStatus.Success) {
                        if (response.payload.data === true) {
                            setValidationStatus('valid');
                        } else {
                            setValidationStatus('invalid');
                        }
                    }
                } else {
                    toast.error("error checking details");
                    setValidationStatus('error');
                }
            });
        } catch (error: any) {
            toast.error("Unable to update password:  " + error);
        }
    }



    return (
    <div className={"container"}>
        <img alt="logo" src={logo} />
        <br />
        <br />
      <h2>Email Validation</h2>


      {validationStatus === 'validating' && <p>Email validation in progress! <br /><br /> <Loading /></p>}
      {validationStatus === 'valid' && <p>Email validation successful! (Redirecting to login...) <br /><br /> <Loading /></p>}
      {validationStatus === 'invalid' && <p>Invalid or expired validation link. Please contact support.</p>}
      {validationStatus === 'error' && <p>An error occurred during validation. Please try again or contact support.</p>}
      {validationStatus === 'password' &&
        <div>
            <p>Please enter your new password below to complete your verification.</p>
            <NewPassword onValidationChange={handlePasswordValidation} />
            <div className={"d-grid"}>
                <br />
                <Button
                    variant="contained"
                    disabled={!pwValid}
                    className={"btn btn-theme btn-auth"}
                    onClick={() => {
                        updatePassword();
                    }}
                >
                    Update
                </Button>
            </div>
        </div>
      }
    </div>
    );
};

export default EmailValidationPage;