import "./MinimalLayout.css";
import React, { useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import { registerLicense } from "@syncfusion/ej2-base";
import { ToastContainer } from "react-toastify";
import { userManager } from "../../../../common/user-service";

registerLicense(process.env.REACT_APP_SYNCFUSION_LICENSE_KEY as string);

const initLogin = (): void => {

	localStorage.setItem('redirect_url', window.location.href);

  	userManager.signinRedirect({
		state: { returnUrl: window.location.href }
  	});
}

const goLogout = (): void => {(
	userManager.signoutRedirect()
)};

export default function MinimalLayout() {

	const [isAuthenticated, setIsAuthenticated] = useState(false);

	useEffect(() => {
		const checkUser = async () => {
			var user = await userManager.getUser();
			if (user == null) {
				initLogin();
			} else {
				setIsAuthenticated(true);
				const redirectUrl = localStorage.getItem('redirect_url');
				if (redirectUrl !== null) {
					localStorage.removeItem('redirect_url');
					window.location.href = redirectUrl;
				}
			}
		};

		checkUser();
	}, []);



	return (
		<>
		{isAuthenticated ? (
			<>
				<ToastContainer
					position="top-right"
					autoClose={3000}
					limit={3}
					hideProgressBar={true}
					newestOnTop={true}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
					theme="light"
				/>
				<div id="sidebar">
					<nav>
						<ul>
							<li>
								<Link to={`/home/users`}>Users</Link>
							</li>
							<li>
								<Link to={`/home/roles`}>Roles</Link>
							</li>
							<li>
								<Link to={`/home/auditconfig`}>Audit Configuration</Link>
							</li>
							<li>
								<Link to={`/home/my-account`}>My Account</Link>
							</li>
							<li>&nbsp;</li>
							<li>
								<button onClick={goLogout} className="btn btn-dark btn-sm">Logout</button>
							</li>
						</ul>
					</nav>
				</div>
				<div id="detail">
					<Outlet />
				</div>
			</>
		) : (
			<>
				Loading...
			</>
		)}
		</>
	);
}
