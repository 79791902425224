import React, { useEffect, useRef, useState } from 'react';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { registerLicense } from "@syncfusion/ej2-base";
import ProfilePicture from '../Common/profile-picture/upload';
import {AuditModal} from "@ibcos/auditviewer";
import { userManager } from '../../../../common/user-service';
import ApiService from "../../../../common/api-service";
import { UserProfile } from '../../../../models/User';
import { toast } from 'react-toastify';
import { ResponseStatus } from '../../models/Enum';
import { Company } from "../../models/Company";
import NewPassword from '../NewPassword/NewPassword';

import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { Card, FormControl, FormLabel, FormSelect } from 'react-bootstrap';
import { UserDetail } from '../../models/UserDetail';
import { FormValidator, FormValidatorModel } from '@syncfusion/ej2-react-inputs';
import { auditUrl } from '../../../../common/urls';


registerLicense(process.env.REACT_APP_SYNCFUSION_LICENSE_KEY as string);

const MyAccount: React.FC<{showAudit: boolean}> = ({showAudit}) => {

    const [user, setUser] = useState<UserDetail | null>(null);
    const [profile, setProfile] = useState<UserProfile | null>(null);
    const [newEmail, setNewEmail] = useState<string>("");
    const [username, setUsername] = useState<string>("");
    const [oldPassword, setOldPassword] = useState<string>("");
    const [newPassword, setNewPassword] = useState<string>("");
    const [newDefaultCompany, setNewDefaultCompany] = useState<string>("");
    const [newDefaultDepot, setNewDefaultDepot] = useState<string>("");
    const [companies, setCompanies] = useState<Company[]>([]);
    const [changePassword, setChangePassword] = useState<boolean>(false);
    const [showUpload, setShowUpload] = useState<boolean>(false);
    const [defaultCompany, setDefaultCompany] = useState<string>('');
    const [referrer, setReferrer] = useState<string>('');
    const formValidator = useRef<FormValidator>();

    const checkDefaultCompany = () => {
        if (user && user.defaultCompanyId) {
            companies.forEach(function(company: Company) {
                if (company.id === user.defaultCompanyId) {
                    setDefaultCompany(company.name);
                }
            })
        }
        if (user && user.defaultDepotId) {
            setNewDefaultDepot(user.defaultDepotId);
        }
    };

    function setupValidation() {
        const options: FormValidatorModel = {
            rules: {
                title:      { maxLength: 255,   required: true },
                firstName:  { maxLength: 255,   required: true },
                lastName:   { maxLength: 255,   required: true },
                email:      { maxLength: 128,   required: true,     email: true}
            },
          };
          formValidator.current = new FormValidator("#form-group", options);
    }

    useEffect(() => {

        setReferrer(document.referrer);
        if(window.location.href.match(/returnUrl=([^&]*)/)){
            setReferrer(decodeURIComponent(window.location.href.match(/returnUrl=([^&]*)/)![1]));
        }

        const fetchProfile = async () => {
			try {
				const response = await ApiService.get("profile/get");
				if (response.data && response.data.data && response.data.responseStatus === ResponseStatus.Success) {
                    const profileData = response.data.data;
					setProfile(profileData);
				} else {
					toast.error("Unable to fetch profile. " + response.data.message);
					console.error("Unable to fetch profile. " + response.data.message);
				}
			} catch (error: any) {
				console.error(error);
				toast.error("Unable to fetch data. " + error);
			}
		};
        const fetchUser = async () => {
			try {
				const response = await ApiService.get("user/current");
				if (response.data && response.data.data && response.data.responseStatus === ResponseStatus.Success) {
                    const profileData = response.data.data;
					setUser(profileData);
                    setUsername(profileData.username);
                    setNewEmail(profileData.email);
                    setNewDefaultCompany(profileData.defaultCompanyId);
				} else {
					toast.error("Unable to fetch user. " + response.data.message);
					console.error("Unable to fetch user. " + response.data.message);
				}
			} catch (error: any) {
				console.error(error);
				toast.error("Unable to fetch user. " + error);
			}
		};

        const fetchCompanies = async () => {
            try {
                const response = await ApiService.get("depot/list-by-company");
                const companyList: Company[] = response.data.data;
                setCompanies(companyList);
                checkDefaultCompany();
            } catch (error: any) {
                console.error(error);
                toast.error("Unable to get companies. " + error);
            }
        };
        
        fetchProfile();
        fetchUser();
        fetchCompanies();

        setupValidation();

    }, []);
    
    useEffect(() => { 
        checkDefaultCompany();
    }, [user, companies]);

    const handlePasswordValidation = (newPassword: string, isValid: boolean) => {
        setNewPassword(newPassword);
	}
    
    async function getUserToken(): Promise<string> {
        var user = await userManager.getUser();
        
        if (user.expired) {
            await userManager.signinSilent();
            user = await userManager.getUser();
        }

        return user?.access_token == null ? '' : user.access_token;
    };

    const handleNewImageBase64 = (data: string) => {
        setProfile(profile => ({
            ...profile,
            image: data,
        }));
    };

    async function updatePassword(): Promise<boolean> {
        try {
            var data:any = {};
            data.username = user.username;
            data.password = oldPassword;
            data.passwordNew = newPassword;
            const response = await ApiService.postNoHeaders("user/new-password", data);

            if (response.data && response.data.data) {
                switch (response.data.responseStatus) {
                    case ResponseStatus.Success : 
                        setChangePassword(false);
                        toast.success("Password updated successfully");
                        return true;
                    
                    case ResponseStatus.Error: 
                        toast.error(response.data.data);
                        return false;

                    case ResponseStatus.Warning : 
                        toast.warning(response.data.data);
                        return false;
                    default:
                        toast.error("Unknown response from server.")
                        return false;
                }
            };

        } catch (error: any) {
            toast.error("Error sending request.");    
            return false;
        };

        toast.error("Password update failed");  //shouldnt happen  
        return false;
    };

    async function updateUser(): Promise<boolean> {

        try {
            user.email = newEmail;
            user.defaultCompanyId = newDefaultCompany;
            user.defaultDepotId = newDefaultDepot;
            const response = await ApiService.post("user/profile-user-update", user);
            if (response.data && response.data.data && response.data.responseStatus === ResponseStatus.Success) {
                return true;
            };
        } catch (error: any) {
                return false;
        };

        return false;
    };

    const buttonClickUpdatePassword = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event?.preventDefault();
        setChangePassword(true)
    }

    const buttonClickUpdatePicture = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event?.preventDefault();
        setShowUpload(true)
    }

    const saveProfile = async (event: React.MouseEvent<HTMLFormElement>) => {
        event?.preventDefault();
        if (formValidator.current?.validate()) {
            try {
                if (user!.email !== newEmail || user!.DefaultCompanyId !== newDefaultCompany) {
                    if (!await updateUser()) {
                        throw new Error("Failed to update user. Aborted further calls.")
                    }
                }
                const response = await ApiService.post("profile/update", profile);
                if (response.data && response.data.data && response.data.responseStatus === ResponseStatus.Success) {
                    toast.success("Updated profile!");
                    checkDefaultCompany();
                } else {
                    toast.error("Unable to update profile. " + response.data.message);
                    console.error("Unable to update profile. " + response.data.message);
                }
            } catch (error: any) {
                console.error(error);
                toast.error("Unable to update data. " + error);
            }
        }
    };

    const updateProfileEntry = (parameter: string, newValue: string) => {
        setProfile(profile => ({
            ...profile,
            [parameter]: newValue,
        }));
    };

    const handleFieldChange = (parameterName: string) => (e: any) => {
        e.preventDefault();
        updateProfileEntry(parameterName, e.target.value);
    }

    const handleFieldToggle = (parameterName: string) => (e: any) => {
        updateProfileEntry(parameterName, e.target.checked);
    }

    var buttons = [
		{
			click: () => {
				setChangePassword(false);
			},
			buttonModel: {
				content: 'Cancel',
				cssClass: 'btn-outline-theme btn px-4',
			},
		},
		{
			click: () => {
				updatePassword();
			},
			buttonModel: {
				content: 'Update',
				cssClass: 'btn-theme btn px-4', //primary
			},
		},
	];

    const handleBackClick = () => {
        if (referrer) {
            document.location.href = referrer;
        } else {
            window.history.back();
        }
    }

    const handleDepotSwitch = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setNewDefaultDepot(e.target.value);
        setNewDefaultCompany(e.target.selectedOptions[0].dataset.companyId!);
    }

    const auditApiUrl = auditUrl;

    return (
        <>
            {showAudit ? (<AuditModal getToken={getUserToken} auditApiUrl={auditApiUrl} />) : (<></>)}
            <div>
                <h1 className='text-theme fw-bold mb-5'>Manage Account</h1>
                <form id="form-group" action="none" onSubmit={saveProfile}>
                    <div className="row">
                        <div className="col-lg-4 col-md-5">
                            <Card className='shadow border-0 mb-4'>
                                <Card.Body className='py-5 px-4'>
                                    <div className="d-flex align-items-center justify-content-between mb-4">
                                        <button className='btn btn-outline-theme px-4' onClick={buttonClickUpdatePicture}>Upload Photo</button>
                                        <button className='btn btn-outline-theme px-4' onClick={buttonClickUpdatePassword}>Change Password</button>
                                    </div>
                                    <div id="profilepic" className='mb-4'>
                                        <ProfilePicture onImageUpdate={handleNewImageBase64} onLoad={profile?.imagePath} showUpload={showUpload} hideUpload={() => {setShowUpload(false)}} user={profile!} username={user?.username} />
                                    </div>
                                    <h4 className='text-theme text-center fw-bold'>{username}</h4>
                                    <h6 className='text-center text-secondary'>{defaultCompany}</h6>
                                </Card.Body>
                            </Card>
                            {window.top?.location.href.includes('dcp') ? (
                                <Card className='shadow border-0'>
                                    <Card.Body className='py-5 px-4'>
                                        <h4 className="text-theme mb-4 fw-bold">Contact Preferences:</h4>
                                        <div className='mb-3'>
                                            <CheckBoxComponent id="checked" checked={profile?.smsUpdateEnabled === true} onChange={handleFieldToggle('smsUpdateEnabled')}></CheckBoxComponent>
                                            <label htmlFor="checked" className='ms-4 fw-semibold'> Get Updates Via SMS? </label>
                                        </div>
                                        <div className='mb-3'>
                                            <CheckBoxComponent id="checked2" checked={profile?.phoneContactPreference === true} onChange={handleFieldToggle('phoneContactPreference')}></CheckBoxComponent>
                                            <label className='ms-4 fw-semibold' htmlFor="checked2"> Phone </label>
                                        </div>
                                        <div className='mb-3'>
                                            <CheckBoxComponent id="checked3" checked={profile?.emailContactPreference === true} onChange={handleFieldToggle('emailContactPreference')}></CheckBoxComponent>
                                            <label className='ms-4 fw-semibold' htmlFor="checked3" > Email </label>
                                        </div>
                                        <div className='mb-3'>
                                            <CheckBoxComponent id="checked4" checked={profile?.postContactPreference === true} onChange={handleFieldToggle('postContactPreference')}></CheckBoxComponent>
                                            <label className='ms-4 fw-semibold' htmlFor="checked4"> Post </label>
                                        </div>
                                        <div className='mb-3'>
                                            <CheckBoxComponent id="checked5" checked={profile?.textContactPreference === true} onChange={handleFieldToggle('textContactPreference')}></CheckBoxComponent>
                                            <label className='ms-4 fw-semibold' htmlFor="checked5"> Text </label>
                                        </div>
                                    </Card.Body>
                                </Card>
                            ) : (<></>)}
                        </div>
                        <div className="col-lg-8 col-md-7">
                            <Card className='shadow border-0'>
                                <Card.Body className='p-5'>
                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <FormLabel className="fw-semibold">Title <sup className="text-danger">*</sup></FormLabel>
                                        <FormControl type="text" name="title" value={profile?.title} placeholder="Title" onChange={handleFieldChange('title')} required/>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <FormLabel className="fw-semibold">First Name <sup className="text-danger">*</sup></FormLabel>
                                        <FormControl type="text" name="firstName" placeholder="First Name" value={profile?.firstName} onChange={handleFieldChange('firstName')} required/>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <FormLabel className="fw-semibold">Last Name <sup className="text-danger">*</sup></FormLabel>
                                        <FormControl type="text" name="lastName" value={profile?.lastName} onChange={handleFieldChange('lastName')} required/>
                                    </div>
                                    {window.top?.location.href.includes('dcp') ? (<></>) : (
                                        <div className="col-md-6 mb-3">
                                            <FormLabel className="fw-semibold">Default Company/Depot <sup className="text-danger">*</sup></FormLabel>
                                            <FormSelect id="DefaultDepotId" onChange={handleDepotSwitch} value={newDefaultDepot}>
                                                {companies?.map((company, index) => {
                                                    return (
                                                        <>
                                                        {/* <option key={index} value={company.companyId} disabled >
                                                            {company.companyName}
                                                        </option> */}
                                                        {company.depots?.map((depot, dIndex) => {
                                                            return (
                                                            <option key={dIndex} value={depot.id} data-company-id={company.companyId} >
                                                                {company.companyName} - {depot.name}
                                                            </option>
                                                            )
                                                        })}
                                                        </>
                                                    );
                                                })}
                                            </FormSelect>

                                            {/* <FormSelect id="DefaultDepotId" onChange={handleDepotSwitch} value={newDefaultDepot}>
                                                {companies?.map((company, index) => {
                                                    return (
                                                        <>
                                                        <option key={index} value={company.companyId} disabled >
                                                            {company.companyName}
                                                        </option>
                                                        {company.depots?.map((depot, dIndex) => {
                                                            return (
                                                            <option key={dIndex} value={depot.id} data-company-id={company.companyId} >
                                                                &nbsp; &nbsp; {depot.name}
                                                            </option>
                                                            )
                                                        })}
                                                        </>
                                                    );
                                                })}
                                            </FormSelect> */}
                                        </div>  
                                    )}
                                    <div className="col-md-12">
                                        <hr className='my-4'/>
                                    </div>
                                    <div className="col-md-12 mb-3">
                                            <FormLabel className="fw-semibold">Address</FormLabel>
                                            <FormControl className='mb-3' value={profile?.addressLine1} onChange={handleFieldChange('addressLine1')} placeholder='Address Line 1'/>
                                            <FormControl value={profile?.addressLine2} onChange={handleFieldChange('addressLine2')} placeholder='Address Line 2'/>
                                        </div>
                                    <div className="col-md-6 mb-3">
                                        <FormLabel className="fw-semibold">City</FormLabel>
                                        <FormControl type="text" placeholder="City" value={profile?.addressLine3} onChange={handleFieldChange('addressLine3')}/>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                    <FormLabel className="fw-semibold">Email Address <sup className="text-danger">*</sup></FormLabel>
                                    <FormControl type="text" name="email" value={newEmail} onChange={(e) => {setNewEmail(e.target.value)}} placeholder="Email"/>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <FormLabel className="fw-semibold">Post Code</FormLabel>
                                        <FormControl type="text" value={profile?.addressPostcode} onChange={handleFieldChange('addressPostcode')} placeholder="Post Code"/>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <FormLabel className="fw-semibold">Phone Number </FormLabel>
                                        <FormControl type="text" value={profile?.phoneNo} onChange={handleFieldChange('phoneNo')} placeholder="Phone Number"/>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <FormLabel className="fw-semibold">Mobile Number</FormLabel>
                                        <FormControl type="text" value={profile?.mobileNo} onChange={handleFieldChange('mobileNo')} placeholder="Mobile Number"/>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <FormLabel className="fw-semibold">Alternate Number </FormLabel>
                                        <FormControl type="text" value={profile?.alternatePhoneNo} onChange={handleFieldChange('alternatePhoneNo')} placeholder="Alt Number"/>
                                    </div>
                                    <div className="text-md-end mt-4">
                                        {window.self !== window.top ? (<></>) : (
                                            <button type='button' className='btn btn-outline-theme px-4 me-3' onClick={(e) => {e.preventDefault(); handleBackClick();}}>Cancel</button>
                                        )}
                                        <button type='submit' className='btn btn-theme px-4'>Save Changes</button>
                                    </div>
                                </div>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>  
                </form>          
                <DialogComponent width="500px" visible={changePassword} buttons={buttons} title="Update Password" target={'#root'}>
                    <div className="mb-4">
                        <label htmlFor="existingPW" className="fw-bold">Existing Password</label>
                        <input id="existingPW" className='form-control' placeholder="Existing Password" type={'password'} onChange={(e) => {setOldPassword(e.target.value)}}/>
                    </div>
                    <NewPassword onValidationChange={handlePasswordValidation} />
                </DialogComponent>
                <DialogComponent width="300px" visible={!user || !profile} isModal={true} title="Loading Profile" target={'#root'}>
                    <div className="mb-4">
                        Loading Profile. Please Wait...
                    </div>
                </DialogComponent>
            </div>
        </>
    );
};

export default MyAccount;
