import React, { useEffect, useState } from 'react';
import { useParams, redirect } from 'react-router-dom';
import { validateAccount, activateAccount } from '../../../../store/modules/UserSlice';
import { useAppDispatch } from '../../../../hooks/hooks';
import { UserValidate, UserActivate } from '../../models/UserDetail';
import { toast } from 'react-toastify';
import { Button } from 'react-bootstrap';
import { ResponseStatus } from '../../models/Enum';
import NewPassword from '../NewPassword/NewPassword';
import logo from '../../../../assets/ibcos_black.jpg';
import Loading from '../../../../common/loading';
import { portalUrl } from '../../../../common/urls';

interface Params {
  email: string;
  token: string;
  userName: string;
}

const EmailValidationPage: React.FC = () => {
  const [validationStatus, setValidationStatus] = useState<'validating' | 'password' | 'valid' | 'invalid' | 'error'>('validating');
  const { email, userName, token } = useParams<Params>();
  const dispatcher = useAppDispatch();
  const [password, setPassword] = useState('');
  const [isValid, setIsValid] = useState<boolean>(false);


    const handlePasswordValidation = (password: string, isValid: boolean) => {
        setPassword(password);
        setIsValid(isValid);
    }

  useEffect(() => {
    // Simulate email validation logic. You can replace this with your actual validation code.
    // For example, make an API request to validate the token on your server.
    if (token) {
      // API REQUEST TO VALIDATE TOKEN
      //console.log('status update: ' + validationStatus);
      
      switch (validationStatus) {
          case 'validating': 
                ValidateDetails();
                break;
            case 'password': 
                //enter password
                //submit
                break;
            case 'valid':
                setTimeout(() => {
                    window.location.replace(portalUrl + '/#/home/my-account');;
                }, 1500);
        }

    } else {
        //ERROR/FAILURE
      setValidationStatus('error');
    }
  }, [token, validationStatus, email, userName]);

function ValidateDetails() {
    try {
        const userDetails: UserValidate = {
            email: email ?? '',
            username: userName ?? '',
            token: token ?? ''
        };

        dispatcher(validateAccount(userDetails)).then((response: any) => {
            
            if (response.payload !== undefined) {
                if (response.payload.responseStatus === ResponseStatus.Success) {
                    if (response.payload.data === true) {
                        setValidationStatus('password');
                    } else {
                        setValidationStatus('invalid');
                    }
                }
            } else {
                toast.error("error checking details");
                setValidationStatus('error');
            }
        });
    } catch (error: any) {
        toast.error("Unable to update password:  " + error);
    }
}

const handleChange = (e: any) => {
    const { id, value } = e.target;
    if (id === 'password') {
        setPassword(value);
        validatePassword();
    }
    if (id === 'confirmPassword') {
        setConfirmPassword(value);
        validatePassword();
    }
};
const validatePassword = () => {
    if (password !== confirmPassword) {
        setPasswordMessage("Password and Confirm Password are different");
    } else {
        setPasswordMessage("");
    }
    return passwordMessage;
};

function updatePassword() {
    try {
        const userDetails: UserActivate = {
            email: email ?? '',
            username: userName ?? '',
            token: token ?? '',
            password: password
        };

        if (password.length === 0) {
            return;
        }

        dispatcher(activateAccount(userDetails)).then((response: any) => {
            
            if (response.payload !== undefined) {
                if (response.payload.responseStatus === ResponseStatus.Success) {
                    if (response.payload.data === true) {
                        setValidationStatus('valid');
                    } else {
                        setValidationStatus('invalid');
                    }
                }
            } else {
                toast.error("error checking details");
                setValidationStatus('error');
            }
        });
    } catch (error: any) {
        toast.error("Unable to update password:  " + error);
    }
}

  return (
<div className={"container"}>
        <img alt="logo" src={logo} />
        <br />
        <br />
      <h2>Reset Password</h2>


      {validationStatus === 'validating' && <p>Validating Link. <br /><br /> Please wait.<br /><br /> <Loading /></p>}
      {validationStatus === 'valid' && <p>Password reset successful! (Redirecting to login...) <br /><br /> <Loading /></p>}
      {validationStatus === 'invalid' && <p>Invalid or expired link. Please contact support.</p>}
      {validationStatus === 'error' && <p>An error occurred during validation. Please try again or contact support.</p>}
      {validationStatus === 'password' && 
        <div>
            <p>Please enter your new password below:</p>
            
            <NewPassword onValidationChange={handlePasswordValidation} />
            <div className={"d-grid"}>
                <br />
            <Button
                variant="contained"
                disabled={!isValid}
                className={"btn btn-theme btn-auth"}
                onClick={() => {
                    updatePassword();
                }}
            >
                Update
            </Button>
            </div>
        </div>
      }
    </div>
  );
};

export default EmailValidationPage;
